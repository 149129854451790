import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import RecommendationHome from "../components/recommendation-home"

const Index = ({ data, location }) => {

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
  }

  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  const toner = shuffleArray(data.toner.edges)
  const moisturizer = shuffleArray(data.moisturizer.edges)
  const eyecare = shuffleArray(data.eyecare.edges)
  const serum = shuffleArray(data.serum.edges)
  const makeup = shuffleArray(data.makeup.edges)
  const masks = shuffleArray(data.masks.edges)
  

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet>
        <title>Collective Cosmetics - Newest and hottest tops - your ultimate tops destination.  </title>
        <link rel="canonical" href="https://www.collectivecosmetic.com/" />      
        <meta name="description" content="Welcome to Collective Cosmetics; your all-in-one beauty destination. We curate the best of beauty from across the web. Born from a love for all things beautiful and a need to simplify the way these skincare and beauty products are discovered and shopped for, we have created an empowering space dedicated to exploring the world of cosmetics."/>
  
  <script type="application/ld+json">
          {`
            {
              "@context": "http://www.schema.org",
              "@type": "Organization",
              "name": "Collective Cosmetics",
              "url": "https://www.collectivecosmetic.com",
              "logo": "https://www.collectivecosmetic.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "image": "https://www.collectivecosmetic.com/static/4dcefb04f813c8b4dfc1c8dfe427f5e8/8acde/logo-crop.avif",
              "description": "Welcome to Collective Cosmetics; your all-in-one beauty destination. We curate the best of beauty from across the web. Born from a love for all things beautiful and a need to simplify the way these skincare and beauty products are discovered and shopped for, we have created an empowering space dedicated to exploring the world of cosmetics."
            }
        `}
        </script>
      </Helmet>
      <div>
      <h1 class="home-intro">Welcome to Collective Cosmetics; your all-in-one beauty destination. We curate the best of beauty from across the web. Born from a love for all things beautiful and a need to simplify the way these skincare and beauty products are discovered and shopped for, we have created an empowering space dedicated to exploring the world of cosmetics.</h1>
      <h2 class="home-intro">We at Collective Cosmetics believe makeup and skincare should be fun, purposeful, and exciting. Our passion is to build an encompassing beauty ecosystem that sources the most exclusive products ranging from high-end brands to indie favorites, showcasing a wide array of beauty masterpieces to enthrall, enchant, and evoke.
</h2>
<h2 class="home-intro">Join us on our journey through an uncontainable universe of beauty. Experience a convertibly vast range of hand-selected products, medias and blogs sharing the latest beauty trends, tips, hacks, and much more. Adequate the mystery of beauty care and step into magic with Collective Cosmetics. Here, beauty is everyone's business!
</h2>
      <RecommendationHome data={toner} name="Toner" collection="toner"/>
      <RecommendationHome data={moisturizer} name="Moisturizer"  collection="moisturizer"/>
      <RecommendationHome data={eyecare} name="Eyecare" collection="eyecare"/>
      <RecommendationHome data={serum} name="Serum" collection="serum"/>
      <RecommendationHome data={makeup} name="Makeup" collection="makeup"/>
      <RecommendationHome data={masks} name="Masks" collection="masks"/>
      </div>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    toner: allAirtable(limit: 50, filter: { table: { eq: "cosmetics_toner" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }
    moisturizer: allAirtable(limit: 50, filter: { table: { eq: "cosmetics_moisturizer" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }
    eyecare: allAirtable(limit: 50, filter: { table: { eq: "cosmetics_eyecare" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }        
    serum: allAirtable(limit: 50, filter: { table: { eq: "cosmetics_serum" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }
    makeup: allAirtable(limit: 50, filter: { table: { eq: "cosmetics_makeup" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }
    masks: allAirtable(limit: 50, filter: { table: { eq: "cosmetics_masks" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description_gpt
          }
        }
      }
    }    
  }
`
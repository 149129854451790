import * as React from "react";
import classNames from "classnames";
import { Link } from "gatsby";

const RecommendationHome = ({ data, name, collection }) => {
  let category = name;
  let moreLink={
    New: 'new-tops',
    Crop: 'tops-crop',
    Corset: 'tops-corset',
    Short: 'tops-short',
    Long: 'tops-long',
    Sleeveless: 'tops-sleeveless',
    Bodysuits: 'tops-bodysuits'    
  }

  let getRecommendation = (data) => {
    return data.slice(0, 6).map((e, index) => {        
        return e.node.data.product_title != null ? (
                <div className="recommendation-product">                    
                  <a
                  class="hover:opacity-75"
                    href={`/products/cc-${e.node.data.product_title                      
                        .trim()
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                  >
                    <img loading="lazy" fetchpirority="low" src={e.node.data.product_image} alt={e.node.data.product_title} />
                  </a>
                
                  <a
                    className="product-link "
                    href={`/products/cc-${e.node.data.product_title
                      .trim()
                      .toLowerCase()
                      .replace(/\s+/g, "-")}`}
                  >
                    <p className="font-bold hover:opacity-75">
                      CT-
                      {e.node.data.product_title
                        .split(/\b/)
                        .reverse()
                        .join("")}
                    </p>
                  </a>
        
                  {/* <span>{data.company}</span> */}
                  <p className="mb-2">
                    {e.node.data.product_price}
                  </p>
                  
                </div>
              ): null; 
    });
  };

  return (
    <div className="home-recommendation-container pt-4 pb-0 mt-4">    
        <h2 class="text-2xl font-extrabold">{category} </h2>        
        <div className="home-recommendation-product-container">

        {data != null ? getRecommendation(data) : null}            
            
        </div>    
        <Link className="home-recommendation-more-btn" to={collection}>View More</Link>
        
    </div>
  );
};

export default RecommendationHome;
